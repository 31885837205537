<template>
  <div class="workOrder">
    <HeaderBar title="联系客服" :showLeft="true" :showRight="true" righttxt="微信客服"></HeaderBar>
    <nav-bar
      :navItems="navItems"
      :activeItemId="activeId"
      @tab="tabNav"
      :showicon="false"
      :isStartWX="false"
    ></nav-bar>
    <div class="topbox"></div>
    <div class="box">
      <div class="box1" v-if="activeId==0">
        <div class="box1Item" v-for="(item,index) in list1" :key="item.SDcCode">
          <div class="sname" @click="handsname(index,item.SDcCode)">
            <span>{{item.SName}}</span>
            <span>
              <img
                :class="item.SDcCode==aid || item.SDcCode==bid?'lefticon':''"
                src="../../assets/leftiicons.png"
                alt
                srcset
              />
            </span>
          </div>
          <div
            class="schild"
            v-show="item.SDcCode==aid || item.SDcCode==bid?true:false"
            v-for="(items,index) in item.ItemList"
            :key="`items+${index}`"
          >
            <div class="stitle" @click="handstitle(items)">
              <span>{{items.Cname}}</span>
              <span>
                <img src="../../assets/righticons.png" alt srcset />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2" v-if="activeId==1">
        <div class="box2item" v-for="item in orders" :key="item.ID" @click="handorder(item)">
          <div class="itemleft">
            <p class="Title">{{item.Title}}</p>
            <p class="ContentInfo">{{item.ContentInfo}}</p>
          </div>
          <div class="itemright">
            <p class="Title">{{item.CreateDate | dateFormat}}</p>
            <span :class="item.ReplyInfo?'State Stateyes':'State'">{{item.ReplyInfo?'已回复':'未回复'}}</span>
          </div>
        </div>
      </div>

      <div class="box3" v-if="activeId==2">
        <p class="prompt">如有问题，可先查询问答专区自行解决，如问题得不到解决，可提交反馈，客服会尽快回复，谢谢！</p>
        <input type="text" v-model="TitleMsg" placeholder="请输入标题（必输项20字）" maxlength="20" />
        <textarea
          class="content"
          name
          id
          cols
          rows="5"
          v-model="Infos"
          placeholder="请输入内容（必输项200字）"
          maxlength="200"
        ></textarea>
        <p class="ptos">上传照片(可选)</p>
        <van-uploader
          v-model="Imgs"
          multiple
          accept="image/*"
          :after-read="afterRead"
          upload-text="点击上传图片"
          :max-count="4"
          @delete="deleteFile"
        />

        <button type="submit" @click="submit">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import NavBar from "@/components/navbar/navbar";
import {
  UserDicCategorysList,
  AddUserMessageInfo,
  UserMessageList
} from "@/service/";
import { Toast, Uploader } from "vant";
import { setStore, getStore, removeStore } from "../../lib/storage";
export default {
  name: "workOrder",
  components: {
    HeaderBar,
    NavBar,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader
  },
  data() {
    return {
      // 导航条激活索引
      activeId: 0,
      navItems: [
        {
          name: "问答专区",
          vid: 0,
          id: "1"
        },
        {
          name: "客服反馈",
          vid: 1,
          id: "2"
        },
        {
          name: "联系客服",
          vid: 2,
          id: "3"
        }
      ],
      orders: [],
      Imgs: [],
      imgBase64: [],
      TitleMsg: "",
      Infos: "",
      aid: "",
      bid: "",
      list1: [],
      issubmit: true
    };
  },
  filters: {
    dateFormat(date) {
      let fmt = "YYYY-mm-dd HH:MM";
      date = new Date(date);
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    }
  },
  computed: {
    umid() {
      return JSON.parse(getStore("userInfo")).ID;
    }
  },
  methods: {
    // tab切换
    tabNav(index) {
      this.activeId = index;
      if (index == 1) {
        this.getUserMessageList();
      } else if (index == 0) {
        this.GetUserDicCategorysList();
      }
      this.TitleMsg = "";
      this.Infos = "";
      this.imgBase64 = [];
      this.Imgs = [];
      this.issubmit = true;
    },
    async GetUserDicCategorysList() {
      if (!this.list1.length > 0) {
        let res = await UserDicCategorysList();
        this.list1 = res.data;
      }
    },
    afterRead(file) {
      //   console.log(file);
      if (/\/(?:jpeg|png)/i.test(file.file.type) && file.file.size > 1500000) {
        // 创建Canvas对象(画布)
        let canvas = document.createElement("canvas");
        // 获取对应的CanvasRenderingContext2D对象(画笔)
        let context = canvas.getContext("2d");
        // 创建新的图片对象
        let img = new Image();
        // 指定图片的DataURL(图片的base64编码数据)
        img.src = file.content;
        // 监听浏览器加载图片完成，然后进行进行绘制
        img.onload = () => {
          // 指定canvas画布大小，该大小为最后生成图片的大小
          canvas.width = 400;
          canvas.height = 300;
          /* drawImage画布绘制的方法。(0,0)表示以Canvas画布左上角为起点，400，300是将图片按给定的像素进行缩小。
          如果不指定缩小的像素图片将以图片原始大小进行绘制，图片像素如果大于画布将会从左上角开始按画布大小部分绘制图片，最后的图片就是张局部图。*/
          context.drawImage(img, 0, 0, 400, 300);
          // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量
          file.content = canvas.toDataURL(file.file.type, 0.92);
          // 最后将base64编码的图片保存到数组中，留待上传。
          if (this.imgBase64.length < 4) {
            this.imgBase64.push(file.content);
          } else {
            Toast("最多上传4张图片");
          }
        };
      } else {
        // 不做处理的jpg和png以及gif直接保存到数组
        if (this.imgBase64.length < 2) {
          this.imgBase64.push(file.content);
        } else {
          Toast("最多上传2张图片");
        }
      }
    },
    deleteFile(file) {
      //   console.log(file);
      let index = this.imgBase64.findIndex(item => item == file.content);
      this.imgBase64.splice(index, 1);
    },
    async submit() {
      if (!this.issubmit) {
        return;
      }

      if (!this.TitleMsg) {
        Toast("请输入标题");
        return false;
      }
      if (!this.Infos) {
        Toast("请输入内容");
        return false;
      }
      let _parms = {
        TitleMsg: this.TitleMsg,
        Infos: this.Infos,
        Umid: this.umid,
        Imgs: JSON.stringify(this.imgBase64)
      };
      this.issubmit = false;
      let res = await AddUserMessageInfo(_parms);
      if (res.code == 1) {
        Toast(res.msg);
        this.TitleMsg = "";
        this.Infos = "";
        this.imgBase64 = [];
        this.Imgs = [];
        this.issubmit = true;
      }
    },
    async getUserMessageList() {
      let res = await UserMessageList(this.umid);
      this.orders = res.data;
    },
    handorder(item) {
      let parms = {
        title: "工单信息",
        item: JSON.stringify(item),
        type: "order"
      };
      setStore("work_order", JSON.stringify(item));
      setStore("work_activeId", this.activeId);
      this.$router.push({
        path: "/details",
        query: parms
      });
    },
    handstitle(item) {
      let parms = {
        title: item.Cname,
        type: "protocol"
      };
      let ids = {
        aid: this.aid,
        bid: this.bid
      };
      setStore("work_activeId", this.activeId);
      setStore("work_protocol", item.Content);
      setStore("work_aid_bid", JSON.stringify(ids));
      this.$router.push({
        path: "/details",
        query: parms
      });
    },
    handsname(index, SDcCode) {
      if (index == 0) {
        this.aid = this.aid ? "" : SDcCode;
      } else if (index == 1) {
        this.bid = this.bid ? "" : SDcCode;
      }
    }
  },
  created() {
    this.activeId = getStore("work_activeId")
      ? Number(getStore("work_activeId"))
      : 0;

    if (this.activeId == 0) {
      this.GetUserDicCategorysList();
    } else if (this.activeId == 1) {
      this.getUserMessageList();
    }
    if (getStore("work_aid_bid")) {
      let ids = { ...JSON.parse(getStore("work_aid_bid")) };
      this.aid = ids.aid;
      this.bid = ids.bid;
      removeStore("work_aid_bid");
    }
    if (getStore("work_order")) {
      removeStore("work_order");
    }
    if (getStore("work_protocol")) {
      removeStore("work_protocol");
    }
    removeStore("work_activeId");
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";
@import "../../styles/orderpop.less";
@import "../../styles/form.less";
.topbox {
  height: 200px;
}
.box {
  //   margin-top: 200px;
  width: 96%;
  padding: 0 2%;
  .box1 {
    .sname {
      //   text-align: center;
      font-size: 30px;
      font-weight: 400;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
        // transform:rotate(90deg);
      }
      .lefticon {
        transform: rotate(90deg);
      }
    }
    .stitle {
      display: flex;
      justify-content: space-between;
      height: 40px;
      padding: 20px 0;
      font-size: 24px;
      span:nth-child(1) {
        margin-left: 20px;
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
    .schild {
      .scontent {
        p {
          margin: 0 !important;
          text-indent: 20px !important;
        }
      }
    }
  }
  .box2 {
    .box2item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #999999;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      div > p:nth-child(1) {
        margin-bottom: 0;
      }
      .Title {
        font-size: 34px;
        font-weight: 400;
      }
      .itemleft {
        width: 55%;
        margin-right: 5%;
      }
      .itemright {
        width: 40%;
        text-align: right;

        .State {
          display: block;
          width: 100px;
          text-align: center;
          margin-top: 20px;
          padding: 10px;
          background: #bebebe;
          color: #fff;
          float: right;
          border-radius: 10px;
        }
        .Stateyes {
          background: rgb(12, 170, 50);
        }
      }
    }
  }
  .box3 {
    .van-uploader__preview {
      .van-image {
        .van-image__img {
          overflow: hidden !important;
        }
      }
    }
    .prompt {
      color: red;
    }
    textarea {
      width: 98%;
      font-size: 30px;
      border-radius: 10px;
      border: 1px solid #a9a9a9;
      box-shadow: 0 0 10px #a9a9a9;
      padding: 1%;
    }
    input {
      width: 98%;
      font-size: 30px;
      border-radius: 10px;
      border: 1px solid #a9a9a9;
      box-shadow: 0 0 10px #a9a9a9;
      padding: 20px 1%;
      margin-bottom: 30px;
    }
    input::-webkit-input-placeholder {
      color: #bebebe;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #bebebe;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #bebebe;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bebebe;
    }
    textarea::-webkit-input-placeholder {
      color: #bebebe;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #bebebe;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #bebebe;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bebebe;
    }
    .title {
      margin-bottom: 20px;
    }
    .ptos {
      font-size: 30px;
      font-weight: bold;
      color: #000;
    }
    .upload {
      &-box {
        width: 180px;
        height: 120px;
        border: 2px solid rgba(238, 238, 238, 1);
        position: relative;
      }
      &-icon {
        width: 50px;
        height: 50px;
        margin: 0 auto;

        &-line {
          width: 50px;
          height: 4px;
          margin: 42px auto 0;
          background: rgba(238, 238, 238, 1);
        }
        &-vertical {
          position: absolute;
          width: 4px;
          height: 50px;
          background: rgba(238, 238, 238, 1);
          left: 88px;
          top: 20px;
          right: 88px;
        }
      }
      &-text {
        margin: 0 auto;
        text-align: center;
        font-size: 20px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(204, 204, 204, 1);
        line-height: 28px;
      }
    }
    button {
      width: 80%;
      height: 80px;
      line-height: 80px;
      text-align: center;
      color: #fff;
      background: rgb(12, 170, 50);
      font-size: 36px;
      letter-spacing: 36px;
      font-weight: bold;
      margin-left: 10%;
      margin-top: 20px;
      border: none;
      border-radius: 16px;
      margin-bottom: 100px;
    }
  }
}
</style>