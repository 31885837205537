<template>
  <div class="pad-home">
    <HeaderBar title="联系客服" :showLeft="true"></HeaderBar>
    <div class="phoneImg1">
    </div>
    <div class="phoneImg2">
    </div>
    <div class="phoneImg3">
    </div>
    <div class="phoneImg4">
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'

export default {
  name: 'phone',
  components:{HeaderBar},
  data () {
    return {
     
    }
  },
  computed: {
    
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.pad-home{
  padding-top: 95px;
}
.phoneImg1 {
  .size(750px, 365px);
  background: url('../../assets/conact1.png') 0 0 no-repeat;
  background-size: 750px 365px;
}
.phoneImg2 {
  .size(750px, 290px);
  background: url('../../assets/conact2.png') 0 0 no-repeat;
  background-size: 750px 290px;
}
.phoneImg3 {
  .size(750px, 290px);
  background: url('../../assets/conact3.png') 0 0 no-repeat;
  background-size: 750px 290px;
}
.phoneImg4 {
  .size(750px, 295px);
  background: url('../../assets/conact4.png') 0 0 no-repeat;
  background-size: 750px 295px;
}
</style>

