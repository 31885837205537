<template>
  <div class="details">
    <HeaderBar :title="title" :showLeft="true"></HeaderBar>
    <div class="topbox"></div>
    <div class="protocol" v-if="type =='protocol'">
      <div v-html="content"></div>
    </div>
    <div class="content" v-else>
      <div class="Title">{{content.Title}}</div>
      <div class="CreateDate">{{content.CreateDate | dateFormat}}</div>
      <div class="ContentInfo">
        <p>{{content.ContentInfo}}</p>
        <block v-show="imgs.length>0">
          <p>图片如下：</p>
          <img class="imgs" v-for="(item,index) in imgs" :key="index" :src="item" alt />
        </block>
      </div>
      <div class="ReplyInfo" v-show="content.ReplyInfo">
        <div class="retitle">
          <img class="logo" src="../../assets/logo.png" alt srcset />客服回复
        </div>
        <div>{{content.ReplyInfo}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { setStore, getStore, removeStore } from "../../lib/storage";
export default {
  name: "details",
  components: { HeaderBar },
  data() {
    return {
      title: "",
      type: "",
      imgs: [],
      content: {}
    };
  },
  filters: {
    dateFormat(date) {
      let fmt = "YYYY-mm-dd HH:MM";
      date = new Date(date);
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    }
  },
  created() {
    let query = this.$route.query;
    this.title = query.title;
    this.type = query.type;
    if (query.type == "protocol") {
      this.content = getStore("work_protocol");
    }
    if (query.type == "order") {
      this.content = JSON.parse(getStore("work_order"));
      if (this.content.UploadImg) {
        if (this.content.UploadImg.indexOf(",")) {
          this.imgs = this.content.UploadImg.split(",");
        } else {
          this.imgs.push(this.content.UploadImg);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content,
.protocol {
  width: 96%;
  padding: 2%;
}
.topbox {
  width: 100%;
  height: 100px;
}
.Title {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
}
.CreateDate {
  text-align: right;
}
.ContentInfo {
  width: 96%;
  padding: 2%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px #a9a9a9;
  margin: 40px 0;
  .imgs {
    width: 100px;
    height: 100px;
    margin: 0 20px;
  }
}
.ReplyInfo {
  width: 96%;
  padding: 2%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px #a9a9a9;
  .retitle {
    line-height: 30px;
    .logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    margin-bottom: 20px;
  }
}
</style>