import { render, staticRenderFns } from "./workOrder.vue?vue&type=template&id=36c9b751&scoped=true&"
import script from "./workOrder.vue?vue&type=script&lang=js&"
export * from "./workOrder.vue?vue&type=script&lang=js&"
import style0 from "./workOrder.vue?vue&type=style&index=0&id=36c9b751&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c9b751",
  null
  
)

export default component.exports